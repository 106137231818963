.banner {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 20px;
  min-height: 200px;
  background-color: var(--banner-background-color, #ce1126);
  box-shadow: 0 8px 16px 4px rgba(0, 0, 0, 0.4);
  padding-bottom: 15px;
  width: var(--banner-width, 285px);
  transition: min-height 0.3s ease;
  border-top: 2px solid white;
}

.collapsed {
  min-height: auto;
  padding-bottom: 0;
}

.expanded {
  min-height: 200px;
  padding-bottom: 15px;
}

.bannerContent {
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 1000px;
  opacity: 1;
  overflow: hidden;
}

.hidden {
  max-height: 0;
  opacity: 0;
}

.banner::after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 0;
  height: 0;
  border-left: calc(var(--banner-width) / 2) solid transparent;
  border-right: calc(var(--banner-width) / 2) solid transparent;
  border-top: 30px solid var(--banner-background-color, #ce1126);
  filter: drop-shadow(2px 0 0 white) drop-shadow(-2px 0 0 white) drop-shadow(0 2px 0 white);
}

.title {
  width: 100%;
  text-align: center;
  color: var(--logo-background-border-color, #ffffff);
  font-family: 'Anton', sans-serif;
  font-weight: var(--title-font-weight, normal);
  font-size: var(--title-font-size, 2.25rem);
  letter-spacing: var(--title-letter-spacing, 2px);
  text-transform: uppercase;
  padding: 20px 5px 5px 5px;
  line-height: 1;
  text-shadow: var(--text-shadow, 1px 1px 3px rgba(0, 0, 0, 0.5));
  position: relative;
  cursor: default;
}

.accordionIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.accordionIcon i {
  display: inline-block;
  vertical-align: middle;
}

.logoContainer {
  width: 100%;
  padding: 10px 0;
  background-color: var(--logo-background-color, #1e2c56);
  display: flex;
  justify-content: center;
  align-items: center;
}

.listContainer {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
}

.list {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: auto;
  max-width: 100%;
}

.listItem {
  color: var(--logo-background-border-color, #ffffff);
  font-family: 'Anton', sans-serif;
  font-size: var(--item-font-size, 1.5rem);
  letter-spacing: var(--item-letter-spacing, 0.75px);
  padding: 5px 10px;
  line-height: 1.2;
  text-align: left;
  display: block;
  white-space: nowrap;
  text-shadow: var(--text-shadow, 1px 1px 3px rgba(0, 0, 0, 0.5));
  transition: background-color 0.3s ease, color 0.3s ease, text-shadow 0.3s ease;
  cursor: default;
}

.highlighted {
  background-color: rgba(255, 255, 0, 1);
  color: black;
  text-shadow: none;
}

.hoverable span {
  cursor: default;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

@media (max-width: 768px) {
  .banner {
    width: 75%; 
    margin: 10px auto;
    --banner-width: 75%; 
    border-bottom: 2px solid white;
  }
  
  .accordionIcon {
    opacity: 1;
  }
  
  .title {
    cursor: pointer;
    padding: 20px 5px;
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    line-height: 1.2;
    font-size: calc(var(--title-font-size, 2.25rem) * 0.9);
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 80%;
    margin: 0 auto;
    hyphens: auto;
    word-spacing: 0.1em;
  }
  
  .banner::after {
    display: none;
  }
}

/* 
* JavaScript toggle overrides the CSS defaults, more brute force here.
* Shortcut solution for now, will clean this up when ported to the new site 
*/
@media (max-width: 768px) {
  .banner.expanded {
    min-height: 200px !important;
    padding-bottom: 15px !important;
  }
  
  .banner.expanded .title {
    padding: 20px 5px 5px 5px !important;
  }
  
  .banner.expanded .bannerContent {
    max-height: 1000px !important;
    opacity: 1 !important;
  }
  
  .banner.collapsed {
    min-height: auto !important;
    padding-bottom: 0 !important;
  }
  
  .banner.collapsed .bannerContent {
    max-height: 0 !important;
    opacity: 0 !important;
  }
}
